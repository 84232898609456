<template>
    <div id="newsIndexPage">
      <Empty
        v-if="!getLength"
        emptyImg="icon-empty-2"
        tip="お知らせがありません"
      ></Empty>
      <ul class="list" v-else>
        <li class="list-item" @click="jumpTo(2)" v-if="Object.keys(subscriptionType).length!==0">
          <div class="thumb">
            <i class="icon icon-1"></i>
          </div>
          <div class="content">
            <div class="title">
              <div class="txt">{{ subscriptionType.typeName }}</div>
              <!-- <div class="date">12:00</div> -->
            </div>
            <div class="desrc">
              <div class="txt">{{ subscriptionType.title }}</div>
              <div class="dot" v-if="subscriptionType.unread===1"></div>
            </div>
          </div>
        </li>
        <li class="list-item" @click="jumpTo(3)" v-if="Object.keys(discountType).length!==0">
          <div class="thumb">
            <i class="icon icon-2"></i>
          </div>
          <div class="content">
            <div class="title">
              <div class="txt">{{ discountType.typeName }}</div>
              <!-- <div class="date">{{ $utils.formatDate(discountType.sendTime , 'MM/dd') }}</div> -->
            </div>
            <div class="desrc">
              <div class="txt">{{ discountType.title }}</div>
              <div class="dot" v-if="discountType.unread===1"></div>
            </div>
          </div>
        </li>
        <li class="list-item" @click="jumpTo(1)" v-if="Object.keys(systemType).length!==0">
          <div class="thumb">
            <i class="icon icon-3"></i>
          </div>
          <div class="content">
            <div class="title">
              <div class="txt">{{ systemType.typeName }}</div>
              <!-- <div class="date">12:00</div> -->
            </div>
            <div class="desrc">
              <div class="txt">{{ systemType.title }}</div>
              <div class="dot" v-if="systemType.unread===1"></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
</template>

<script>
import { Empty } from '@/components';

export default {
  name: 'NewsP',
  components: {
    Empty
  },
  data () {
    return {
      subscriptionType: {}, // 订阅购买通知消息
      discountType: {}, // 优惠券通知消息
      systemType: {} // 系统消息
    };
  },
  created () {
    this.getMessageCenter();
  },
  mounted () {

  },
  computed: {
    getLength () {
      return Object.keys(this.subscriptionType).length !== 0 || Object.keys(this.discountType).length !== 0 || Object.keys(this.systemType).length !== 0;
    }
  },
  watch: {},
  methods: {

    /* 获取的消息中心详情 */
    getMessageCenter () {
      this.$http.get('mine/messageCenter').then((res) => {
        let { subscriptionType, discountType, systemType } = res.data || {};
        this.subscriptionType = subscriptionType || {};
        this.discountType = discountType || {};
        this.systemType = systemType || {};
      });
    },

    /* 跳转至对应的界面 */
    jumpTo (type) {
      this.$router.push({
        name: 'NewsList',
        params: {
          type
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
  #newsIndexPage {
    .list {
      padding-top: 8px;

      .list-item {
        display: flex;
        align-items: center;
        padding: 32px 28px;

        .thumb {
          flex-shrink: 0;
          width: 80px;
          height: 80px;
          margin-right: 24px;

          .icon {
            width: 100%;
            height: 100%;

            &.icon-1 {
              background-image: url("~@/assets/images/icon_news_03.png");
            }

            &.icon-2 {
              background-image: url("~@/assets/images/icon_news_04.png");
            }

            &.icon-3 {
              background-image: url("~@/assets/images/icon_news_05.png");
            }
          }
        }

        .content {
          flex: 1;
          font-size: 28px;
          line-height: 38px;

          .title,
          .desrc {
            display: flex;
            align-items: center;

            .txt {
              flex: 1;
              width: 0;
              margin-right: 20px;
              .text-overflow();
            }
          }

          .title {
            .date {
              font-size: 20px;
              opacity: 0.8;
            }
          }

          .desrc {
            .txt {
              font-size: 22px;
              opacity: 0.8;
            }

            .dot {
              width: 12px;
              height: 12px;
              background-color: #ed544f;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
</style>